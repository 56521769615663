.drawer-scrollbar {
    overflow: hidden;
}

.drawer-scrollbar:hover {
    overflow-y: auto;
}

.drawer-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.drawer-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.drawer-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.drawer-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #6b6b6b;
}