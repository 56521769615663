.list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.list-item-wrap {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.list-item-wrap.interactive:hover {
    background-color: #f8f9fa;
    cursor: pointer;
}

.list-item-wrap > *:first-child {
    min-width: 40px;
}

.list-item-wrap > *:last-child {
    padding-left: 0.5rem;
}

.list-item-action {
    padding: 1rem 1.5rem;
}

.list-item-action:hover {
    background-color: #f2f2fa;
    cursor: pointer;
}

.list-item-dismiss {
    padding: 1rem 1.5rem;
}

.list-item-dismiss:hover {
    background-color: #faf4f2;
    cursor: pointer;
}