//colors
$primary: #7685de;
$secondary: #e4ecfb;
$success: #12b76a;
$danger: #f04438;
$warning: #f79009;
$info: #b1c4f0;
$light: #f0f5fd;
$dark: #2b2f59;

$gray-100: #f8f9fa;
$gray-200: #f1f3f5;
$gray-300: #e9ecef;
$gray-400: #dee2e6;
$gray-500: #ced4da;
$gray-600: #adb5bd;
$gray-700: #868e96;
$gray-800: #495057;
$gray-900: #343a40;

$fast-color: #F6E4DF;
$flat-color: #DDE8EB;
$be18-color: #FBF0C4;

$theme-colors: (
    "primary":  $primary,
    "secondary":$secondary,
    "success":  $success,
    "info":     $info,
    "warning":  $warning,
    "danger":   $danger,
    "light":    $light,
    "dark":     $dark,

    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-600": $gray-600,
    "gray-700": $gray-700,
    "gray-800": $gray-800,
    "gray-900": $gray-900,

    "flux-card-archive": hsla(0, 0%, 44%, 0.516),
    "cloud-burst": #2b2f59,
    "fast":     $fast-color,
    "flat":     $flat-color,
    "be18":     $be18-color,
);

//borders
$border-width: 1px !default;

$border-radius: 0.5rem !default;
$border-radius-sm: 0.3rem !default;
$border-radius-lg: 1rem !default;

//input
$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1rem !default;

$input-btn-padding-y-sm: 0.4rem !default;
$input-btn-padding-x-sm: 0.8rem !default;

$input-btn-padding-y-lg: 0.75rem !default;
$input-btn-padding-x-lg: 1.5rem !default;

//fonts
$btn-font-weight: 500 !default;

$h1-font-size: 2rem;
$h2-font-size: 1.8rem;
$h3-font-size: 1.6rem;
$h4-font-size: 1.4rem;
$h5-font-size: 1.2rem;
$h6-font-size: 1rem;
