.ag-theme-alpine {
    --ag-alpine-active-color: #7685de;
    --ag-selected-row-background-color: rgba(194, 204, 255, 0.2);
    --ag-row-hover-color: #f6f8ff;
    --ag-odd-row-background-color: #ffffff;
    --ag-background-color: #ffffff;
    --ag-header-background-color: rgba(249, 250, 251, 1);
    --ag-border-color: rgba(234, 236, 240, 1);
    --ag-secondary-border-color: rgba(234, 236, 240, 1);
    --ag-checkbox-unchecked-color: rgba(208, 213, 221, 1);
    --ag-font-family: $font-family-sans-serif
}

.ag-root-wrapper {
    border-radius: 8px;
}

.ag-header-cell {
    font-weight: 500;
    font-size: 12px;
    font-style: normal;
    color: $gray-800;
}