h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 400;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.btn-secondary,
.btn-light,
.btn-link {
    color: $primary;
}


.btn-primary,
.btn-danger,
.btn-primary:hover,
.btn-danger:hover,
.btn-primary:focus,
.btn-primary:disabled,
.btn-outline-primary:hover {
    color: #fff;
}


.transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
        skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
    transform: rotate(180deg);
}

.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition {
    transition: 0.2s;
}

.transition-fast {
    transition: 0.1s;
}

.transition-slow {
    transition: 0.5s;
}

.flux-logo {
    font-family: $flux-logo-font;
    font-weight: $font-weight-bold;
}

.card {
    > .list-group {
        border-top: $card-border-width solid $card-border-color;
        border-bottom: $card-border-width solid $card-border-color;
    }
}

.pointer {
    cursor: pointer;
}

.modal-mm {
    max-width: 700px;
}

.nav-item.dropdown > a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 2rem;
}

#topNavigation::after {
    border: none !important;
    font: normal normal normal 1.25rem/1 bootstrap-icons;
    content: "\F283" !important;
    text-align: right;
    float: right;
}

.hide-caret.dropdown-toggle::after {
    display: none !important;
}

.hide-caret.dropdown-toggle::before {
    display: none !important;
}

#addSystem::after {
    content: "";
}

.btn-white {
    background-color: white;
    color: black;
    border: 1px solid #ebebeb;
}

.btn-white:hover {
    background-color: #f1f1f1;
    color: black;
}

.tab-content,
.tab-pane {
    height: 100%;
}

.main-view {
    /* Firefox */
    height: -moz-calc(100% - 5rem);
    /* WebKit */
    height: -webkit-calc(100% - 5rem);
    /* Opera */
    height: -o-calc(100% - 5rem);
    /* Standard */
    height: calc(100% - 5rem);
    overflow-y: hidden;
}

.h-100-1rem {
    max-height: calc(100% - 1rem);
}
.h-100-2rem {
    max-height: calc(100% - 2rem);
}
.h-100-3rem {
    max-height: calc(100% - 3rem);
}
.h-100-4rem {
    max-height: calc(100% - 4rem);
}
.h-100-17rem {
    max-height: calc(100% - 17rem);
}

.lh-1 {
    line-height: 1 !important;
}
.lh-sm {
    line-height: 1.25 !important;
}
.lh-base {
    line-height: 1.25 !important;
}
.lh-lg {
    line-height: 2 !important;
}

.dashed-boder {
    border-style: dashed;
    border-width: medium;
}

.dashed-boder-dragging {
    border-style: solid;
    border-width: medium;
}

.popover {
    max-width: 500px;
    white-space: pre-wrap;
}

button.accordion-button:focus{
    box-shadow: none;
    outline: none; 
}

.accordion-button:not(.collapsed) {
    background-color: #ffffff;
}