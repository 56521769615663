@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
/* variables and defaults */

$font-family-sans-serif: "Inter", -apple-system, BlinkMacSystemFont, "segoe ui", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$flux-logo-font: "Orbitron";

@import "stylesheets/bsvariables.scss";

/* import bootstrap */

@import "../node_modules/bootstrap/scss/bootstrap.scss";

/* extending bootstrap */

@import "stylesheets/drawer.scss";
@import "stylesheets/aggrid.scss";
@import "stylesheets/listitem.scss";
@import "stylesheets/custom.scss";

